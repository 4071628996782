import React from "react";
import Layout from '../../components/Layout';
import ContactPage from '../../templates/_contact-page';

export default class Page extends React.Component {
  render() {
    return (
      <Layout titlePrefix="Contact">
        <ContactPage />
      </Layout>
    );
  }
};